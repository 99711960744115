import React, { PropsWithChildren, useState } from "react";
import { FiTag } from "@react-icons/all-files/fi/FiTag";
import { IoWarningOutline } from "@react-icons/all-files/io5/IoWarningOutline";

import { useQoplaStore } from "Stores/qoplaStore";
import { MiniMenuCategoryAndProducts, MiniMenu } from "../utils/discountFunctions";
import { Box, Flex, FlexProps, Header, Image, Text } from "Atoms";
import { Languagekey, TranslateFunc, useLanguage } from "LanguageProvider";
import { Accordion, AccordionCloseIcon, AccordionContent, AccordionItem, AccordionTitle } from "Organisms";

const MenuWrapper = ({ children, ...props }: PropsWithChildren<FlexProps>) => {
    return (
        <Flex direction={"column"} width={"100%"} mt={6} backgroundColor={"white"} borderRadius={"md"} p={4} {...props}>
            {children}
        </Flex>
    );
};

const MenuContentItems: React.FC<{
    categoryAndProducts: MiniMenuCategoryAndProducts[];
    defaultImageUrl: string;
    translate: TranslateFunc;
}> = ({ categoryAndProducts, defaultImageUrl, translate }) => {
    const [activeCategoryIndexes, setActiveCategoryIndexes] = useState<number[]>([]);

    const lastCategoryIndex = categoryAndProducts?.length - 1;

    /** Check if the category has products that are discounted */
    const categoryProductsAreDiscounted = (category: MiniMenuCategoryAndProducts) => category?.products?.some(product => !!product?.hasDiscount);

    return (
        <Accordion multi activeIndexes={activeCategoryIndexes} p={0}>
            {categoryAndProducts
                ?.filter(category => !category.isUpsellCategory && categoryProductsAreDiscounted(category))
                ?.map((category, categoryIndex) => {
                    const productsHaveDiscounts = category?.products?.every(product => !!product?.hasDiscount);
                    const isLastCategory = categoryIndex === lastCategoryIndex;
                    const hasSomeDiscountedProducts = category?.hasSomeDiscountedProducts ?? false;

                    return (
                        <AccordionItem
                            isActive={activeCategoryIndexes.includes(categoryIndex)}
                            key={category?.id!}
                            {...(isLastCategory && { border: "none" })}
                        >
                            <AccordionTitle
                                fontWeight={"600"}
                                onClick={() => {
                                    setActiveCategoryIndexes(current => {
                                        if (current.includes(categoryIndex)) {
                                            return [];
                                        }
                                        return [categoryIndex];
                                    });
                                }}
                            >
                                <>
                                    <Image
                                        src={!!category?.imageUrl ? category.imageUrl : defaultImageUrl}
                                        width={"20px"}
                                        borderRadius={"md"}
                                        objectFit={"cover"}
                                    />
                                    <Box
                                        as={productsHaveDiscounts ? FiTag : IoWarningOutline}
                                        color={productsHaveDiscounts ? "accent" : ""}
                                        ml={2}
                                    />
                                    <Header as="h6" fontSize={"md"} ml={2}>
                                        {category?.name}
                                        {hasSomeDiscountedProducts && (
                                            <Box
                                                as="span"
                                                ml={2}
                                                fontWeight={"normal"}
                                                fontSize={"xs"}
                                                color="green.500"
                                            >
                                                {`* ${translate("someProductsHaveDiscount")}`}
                                            </Box>
                                        )}
                                    </Header>
                                </>

                                <AccordionCloseIcon color={"blue.500"} marginLeft="auto" w="16px" h="16px" />
                            </AccordionTitle>
                            <AccordionContent>
                                <>
                                    {category?.products?.map((product, _) => {
                                        const { noDiscountReasonText, hasDiscount } = product;
                                        return (
                                            <Flex
                                                padding={2}
                                                justifyContent={"flex-start"}
                                                alignItems={"center"}
                                                key={product.id}
                                            >
                                                <Image
                                                    src={!!product?.imageUrl ? product.imageUrl : defaultImageUrl}
                                                    width={"20px"}
                                                    borderRadius={"md"}
                                                    objectFit={"cover"}
                                                />
                                                <Box
                                                    as={hasDiscount ? FiTag : IoWarningOutline}
                                                    color={hasDiscount ? "accent" : ""}
                                                    ml={2}
                                                />
                                                <Flex
                                                    flexDirection={["column", "row"]}
                                                    alignItems={["flex-start", "center"]}
                                                    justifyContent={["center", "flex-start"]}
                                                    width={"100%"}
                                                >
                                                    <Text
                                                        m={0}
                                                        p={0}
                                                        fontSize={"sm"}
                                                        fontWeight={hasDiscount ? "600" : "200"}
                                                        ml={2}
                                                        mt={hasDiscount ? 0 : 3}
                                                    >
                                                        {product.name}
                                                    </Text>
                                                    {!hasDiscount && !!noDiscountReasonText && (
                                                        <Text
                                                            m={0}
                                                            p={0}
                                                            ml={2}
                                                            mt={2}
                                                            fontSize={"xs"}
                                                            color={"red.500"}
                                                        >
                                                            {`** ${translate(noDiscountReasonText as Languagekey)}`}
                                                        </Text>
                                                    )}
                                                </Flex>
                                            </Flex>
                                        );
                                    })}
                                </>
                            </AccordionContent>
                        </AccordionItem>
                    );
                })}
        </Accordion>
    );
};

export const OnlineCheckoutDiscountMenus: React.FC<{
    miniMenus?: (MiniMenu | null)[] | undefined;
}> = ({ miniMenus }) => {
    const [activeMenuIndexes, setActiveMenuIndexes] = useState<number[]>([]);
    const { selectedShop } = useQoplaStore();
    const { translate } = useLanguage();

    if (!miniMenus) {
        return null;
    }

    const logoUrl = selectedShop?.imageUrl;

    return (
        <MenuWrapper px={0}>
            <Accordion multi activeIndexes={activeMenuIndexes}>
                {miniMenus
                    ?.filter(miniMenu => !!miniMenu)
                    .map((miniMenu, index) => {
                        return (
                            <AccordionItem isActive={activeMenuIndexes.includes(index)} key={miniMenu?.id!}>
                                <AccordionTitle
                                    fontWeight={"600"}
                                    onClick={() => {
                                        setActiveMenuIndexes(current => {
                                            if (current.includes(index)) {
                                                return [];
                                            }
                                            return [index];
                                        });
                                    }}
                                    disableColorTransition
                                >
                                    {miniMenu?.name}
                                    <AccordionCloseIcon color={"blue.500"} marginLeft="auto" w="16px" h="16px" />
                                </AccordionTitle>
                                <AccordionContent>
                                    <MenuContentItems
                                        categoryAndProducts={miniMenu?.categories!}
                                        defaultImageUrl={logoUrl ?? ""}
                                        translate={translate}
                                    />
                                </AccordionContent>
                            </AccordionItem>
                        );
                    })}
            </Accordion>
        </MenuWrapper>
    );
};
