import { CHECKOUT_DISCOUNT_VALIDATIONS, Discount, Menu } from "Types";

export type MiniMenuProduct = MiniMenuCategoryAndProducts;

export type MiniMenuCategoryAndProducts = {
    id: string;
    name: string;
    imageUrl: string;
    hasDiscount: boolean;
    noDiscountReasonText?: CHECKOUT_DISCOUNT_VALIDATIONS | null;
    isUpsellCategory: boolean;
    hasSomeDiscountedProducts?: boolean;
    products?: MiniMenuProduct[];
};

export type MiniMenu = {
    id: string;
    name: string;
    categories: MiniMenuCategoryAndProducts[];
};

export const getDiscountMiniMenuDisplay = (
    discount: Discount,
    menu: Menu,
    excludedProducIds: Set<string>
): MiniMenu | null => {
    const { menuCategoryAndProductIds } = discount;
    const currentMenuDiscounts = menuCategoryAndProductIds?.find(menuDiscount => menuDiscount.menuId === menu.id);

    if (!currentMenuDiscounts) {
        return null;
    }

    const { categoryIds, productIds } = currentMenuDiscounts;

    const discountMiniMenu: Partial<MiniMenu> = { id: menu.id, name: menu.name };

    const menuCategoriesAndProducts = menu.menuProductCategories.map(category => {
        const isCategoryDiscounted = categoryIds.includes(category.id);
        if (isCategoryDiscounted) {
            const products = [...category.menuProducts, ...category.menuBundleProducts].map(product => {
                const categoryProduct = "refProduct" in product ? product.refProduct : product.refBundleProduct;
                return {
                    id: categoryProduct.id,
                    name: categoryProduct.name,
                    imageUrl: categoryProduct.imageUrl,
                    hasDiscount: true,
                    notDiscountedReasonText: null
                };
            });
            return {
                id: category.id,
                name: category.name,
                imageUrl: category.imageUrl,
                hasDiscount: true,
                products,
                isUpsellCategory: category.upsellCategory || category.upsellCategoryAtPayment
            };
        }

        const discountedProducts = [...category.menuProducts, ...category.menuBundleProducts].map(menuProduct => {
            const refProduct = "refProduct" in menuProduct ? menuProduct.refProduct : menuProduct.refBundleProduct;

            const isInExcludedList = excludedProducIds.has(menuProduct.id);
            const isProductDiscounted = productIds.includes(menuProduct.id);

            const productNotDiscountedReason = !isProductDiscounted
                ? CHECKOUT_DISCOUNT_VALIDATIONS.NO_DISCOUNT_ON_PRODUCTS
                : null;
            const notDiscountedReason = isInExcludedList
                ? CHECKOUT_DISCOUNT_VALIDATIONS.NO_DISCOUNT_ON_ALCOHOL
                : productNotDiscountedReason;

            /** Need to get name + image from ref but use the menu id for the discount */
            return {
                id: menuProduct.id,
                name: refProduct.name,
                imageUrl: refProduct.imageUrl,
                hasDiscount: isProductDiscounted,
                noDiscountReasonText: notDiscountedReason
            };
        });

        const categoryHasDiscountedProducts = discountedProducts.some(product => product.hasDiscount);

        return {
            id: category.id,
            name: category.name,
            imageUrl: category.imageUrl,
            hasDiscount: false,
            products: discountedProducts,
            hasSomeDiscountedProducts: categoryHasDiscountedProducts,
            isUpsellCategory: category.upsellCategory || category.upsellCategoryAtPayment
        };
    });

    Reflect.set(discountMiniMenu, "categories", menuCategoriesAndProducts);
    return discountMiniMenu as MiniMenu;
};
