import { TranslateFunc } from "LanguageProvider";
import { MenuBundleProduct, MenuProduct, MenuProductCategory, UserSubscriptionDTO } from "Types";

const transformSubscriptionProductsToMenuProducts = (
    userSubscription: UserSubscriptionDTO,
    allProducts: (MenuProduct | MenuBundleProduct)[]
) => {
    return userSubscription.subscription.subscriptionProducts.reduce<{
        menuProducts: MenuProduct[];
        menuBundleProducts: MenuBundleProduct[];
    }>(
        (acc, { __typename, ...next }) => {
            const found = allProducts.find(product => {
                const prod = "refProduct" in product ? product.refProduct : product.refBundleProduct;
                return next.refProductId === prod.id;
            });

            const subscriptionProductMeta = {
                ...next,
                subscriptionId: userSubscription.subscription.id
            };

            if (found) {
                const isBundle = "refBundleProduct" in found;
                if (isBundle) {
                    //@ts-ignore
                    acc.menuBundleProducts.push({ ...found, subscriptionProductMeta });
                } else {
                    //@ts-ignore
                    acc.menuProducts.push({ ...found, subscriptionProductMeta });
                }
            }
            return acc;
        },
        { menuProducts: [], menuBundleProducts: [] }
    );
};

const transformSubscriptionsToMenuProductCategories = (
    userSubscriptions: UserSubscriptionDTO[],
    allProducts: (MenuProduct | MenuBundleProduct)[],
    translate: TranslateFunc
): MenuProductCategory[] => {
    return userSubscriptions.map(userSubscription => {
        const { menuProducts, menuBundleProducts } = transformSubscriptionProductsToMenuProducts(
            userSubscription,
            allProducts
        );
        // it seems like this part always will return true
        /*const hasUserEnrolledSubscription = userSubscriptions.some(
            userSubscription => userSubscription.subscription.id === userSubscription.subscription.id
        );*/

        const name = `${translate("subscriptions")} ${userSubscription.subscription.name}`;

        return {
            id: userSubscription.subscription.id,
            name,
            sortOrder: 0,
            upsellCategory: false,
            upsellCategoryAtPayment: false,
            description: "",
            imageUrl: userSubscription.subscription.imageUrl,
            menuProducts,
            menuBundleProducts,
            isSubscriptionCategory: true,
            posFavorite: false
        };
    });
};

export const getSubscriptionMenuProductCategories = (
    userSubscriptions: UserSubscriptionDTO[],
    allProducts: (MenuProduct | MenuBundleProduct)[],
    showAsEnrolledSubscriptions: boolean,
    translate: TranslateFunc
): MenuProductCategory[] => {
    if (showAsEnrolledSubscriptions) {
        return transformSubscriptionsToMenuProductCategories(userSubscriptions, allProducts, translate);
    }
    return [];
};
