import React, { useEffect } from "react";
import { FiTag } from "@react-icons/all-files/fi/FiTag";
import { IoWarningOutline } from "@react-icons/all-files/io5/IoWarningOutline";
import { Control, useController } from "react-hook-form";

import {
    CAMPAIGN_DISCOUNT_VALIDATION_STATUS,
    CHECKOUT_DISCOUNT_VALIDATIONS,
    CartProduct,
    CountryLocaleId,
    Discount,
    Menu
} from "Types";
import { Modal, ModalActions, ModalBody, ModalCloseBtn, ModalHeader } from "Molecules";
import { Flex, Box, FormLabel, Button, Header, RHCheckBoxInput, Text } from "Atoms";
import { useLanguage } from "LanguageProvider";
import { modalNames } from "Constants";
import { formatDateToLocal, formatFinancialNumbers } from "Utils";
import { useOnlineStore } from "Stores/onlineStore";
import { useQoplaStore } from "Stores/qoplaStore";
import { OnlineCheckoutFormValues } from "../../../OnlineCheckout";
import { Tab, TabList, TabPanel, TabPanels, Tabs } from "Organisms";
import { OnlineCheckoutDiscountMenus } from "./OnlineCheckoutDiscountMenus";
import { useMothershipQuery } from "Hooks";
import { GET_MENU_BY_IDS_NEW } from "GraphQLQueries";
import { getDiscountMiniMenuDisplay } from "../utils/discountFunctions";

type ModalProps = {
    modalContent: {
        email: string;
        discount: Discount;
        cartTotalCost: number;
        control: Control<OnlineCheckoutFormValues>;
        cartProducts: CartProduct[];
        onSetUserDiscount: (discount: Discount) => void;
        onRemoveUserDiscount: () => void;
        shopId: string;
    };
    closeModal: (modal: string) => void;
};

type DisplayProps = {
    id: string;
    name: string;
    canDiscountItem: boolean;
    noDiscountReasonText?: string | null;
};

enum TAB_NAMES {
    DISCOUNT = 0,
    ELIGIBLE_PRODUCTS = 1
}

export const OnlineCheckoutDiscountModal: React.FC<ModalProps> = ({ modalContent, closeModal }) => {
    /** onRemoveUserDiscount not used but leaving here - may need it */
    const { discount, cartProducts, onSetUserDiscount, onRemoveUserDiscount, email, cartTotalCost, control } =
        modalContent;
    const { translate, translateWithArgument } = useLanguage();
    const { companyLocale, selectedShop } = useQoplaStore();
    const { getAlcoholRefProductIds } = useOnlineStore();

    const {
        field: { value: acceptsCampaigns, onChange: onAcceptCampaignsChange }
    } = useController({ control, name: "acceptsCampaigns" });

    const onlineMenuIds = selectedShop?.settings?.onlineSettings?.onlineMenuIds;
    const isSwedishRestaurant = companyLocale?.baseName === CountryLocaleId.sv_SE || true;
    const alcoholRefProductIds = getAlcoholRefProductIds();

    const { data } = useMothershipQuery<{ getMenusByIds: Menu[] }>(GET_MENU_BY_IDS_NEW, {
        variables: { menuIds: onlineMenuIds }
    });

    const onCloseModal = () => {
        closeModal(modalNames.ONLINE_CHECKOUT_DISCOUNT_MODAL);
    };

    const onApplyDiscount = () => {
        onSetUserDiscount(discount);
        onCloseModal();
    };

    /** If the discount is not allowed for that product - this will provide the reason (displayed next to product name) */
    const noDiscountAvailableReason = (cartProduct: CartProduct) => {
        const refProductId = cartProduct.orderProduct?.refProductId ?? cartProduct.orderProduct.refBundleProductId!;
        const isExcludedFromDiscount = alcoholRefProductIds.has(refProductId);
        if (isExcludedFromDiscount && isSwedishRestaurant) {
            return translate(CHECKOUT_DISCOUNT_VALIDATIONS.NO_DISCOUNT_ON_ALCOHOL);
        }

        if (isExcludedFromDiscount) {
            return translate(CHECKOUT_DISCOUNT_VALIDATIONS.NO_DISCOUNT_ON_PRODUCTS);
        }

        if (!!cartProduct?.fixedDiscount) {
            const canCombineDiscount =
                (cartProduct.fixedDiscount && cartProduct.fixedDiscount.canCombineDiscounts) ||
                !cartProduct.fixedDiscount;
            if (!canCombineDiscount) {
                return translate(CHECKOUT_DISCOUNT_VALIDATIONS.NO_COMBINING_DISCOUNTS);
            }
        }

        return translate(CHECKOUT_DISCOUNT_VALIDATIONS.NO_DISCOUNT_ON_PRODUCTS);
    };

    const formattedEndDate = formatDateToLocal(discount.endDate!, "YYYY-MM-DD");
    const discountDisplayed = !!discount.amount
        ? formatFinancialNumbers(discount.amount, companyLocale, undefined, true)
        : `${discount.rate}%`;

    const hasEmail = email?.length > 0;

    const hasDiscountLimit = (discount?.maxRedeemed ?? 0) > 0 && !discount.qoplaSponsored;
    const multiUseDiscount = (discount?.maxRedeemed ?? 0) > 1;

    const isADiscountedProduct = (cartProduct: CartProduct) =>
        discount?.productIdsForDiscount?.includes(cartProduct.id) ?? false;

    useEffect(() => {
        if (!acceptsCampaigns) {
            onAcceptCampaignsChange(true);
        }
    }, []);

    const displayProducts = cartProducts
        .sort((a, b) => (isADiscountedProduct(b) ? 0 : isADiscountedProduct(a) ? -1 : 1))
        .map(cartProduct => {
            const canDiscountItem = isADiscountedProduct(cartProduct);
            const noDiscountReasonText = !canDiscountItem ? noDiscountAvailableReason(cartProduct) : null;
            return {
                id: cartProduct.id,
                name: cartProduct.orderProduct.name,
                canDiscountItem,
                noDiscountReasonText
            } as DisplayProps;
        });

    const noDiscountedProduct = displayProducts.every(display => !display.canDiscountItem);
    const miniMenus = data?.getMenusByIds.map(menu => getDiscountMiniMenuDisplay(discount, menu, alcoholRefProductIds));
    const minimumSpend = discount?.minimumSpend ?? 0;
    const hasEligibleProducts = !!discount.menuCategoryAndProductIds?.length;
    const hasReachedMinSpend = cartTotalCost > minimumSpend;

    const showPersonalisedMessage = hasEmail && !noDiscountedProduct && !Boolean(discount?.qoplaSponsored);

    const applyDiscountText =
        !noDiscountedProduct && hasReachedMinSpend ? translate("applyDiscount") : translate("close");

    return (
        <Modal
            border={"3px solid #E2E8F0"}
            open
            placement="center"
            size={"sm"}
            borderRadius={"2xl"}
            p={2}
            backgroundColor={"gray.100"}
            onClose={onCloseModal}
        >
            <ModalHeader
                color={"#111"}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
                fontSize={"xl"}
            ></ModalHeader>
            <ModalCloseBtn onClick={onCloseModal} />
            <ModalBody mt={0} pt={0} mb={0}>
                <Tabs variant="line">
                    <TabList>
                        <Tab key={TAB_NAMES.DISCOUNT}>{translate("discount")}</Tab>
                        {hasEligibleProducts && (
                            <Tab key={TAB_NAMES.ELIGIBLE_PRODUCTS}>{translate("eligibleProducts")}</Tab>
                        )}
                    </TabList>
                    <TabPanels>
                        <TabPanel key={TAB_NAMES.DISCOUNT}>
                            <Flex
                                direction={"column"}
                                width={"100%"}
                                mt={6}
                                backgroundColor={"white"}
                                borderRadius={"md"}
                                p={4}
                            >
                                <Header
                                    color={"#111"}
                                    display={"flex"}
                                    alignItems={"center"}
                                    justifyContent={"center"}
                                    fontSize={"xl"}
                                >
                                    {discount.name}
                                </Header>
                                <Header
                                    fontSize={"5xl"}
                                    m={0}
                                    p={0}
                                    mb={2}
                                    color={"accent"}
                                    textShadow={"sm"}
                                    ml="auto"
                                    mr="auto"
                                >
                                    {discountDisplayed}
                                </Header>
                                {minimumSpend > 0 && (
                                    <FormLabel ml="auto" mr="auto">
                                        <Box as="span" fontSize="0.8rem" mt={1} color="gray.600" fontStyle="italic">
                                            {`* ${translateWithArgument("minimumSpendText", minimumSpend)}`}
                                        </Box>
                                    </FormLabel>
                                )}
                                {!!discount.endDate && (
                                    <FormLabel mt={2} mb={2} ml="auto" mr="auto">
                                        <Box as="span">{translate("validUntil")}: </Box>
                                        <Box as="span" color="yellow.900" fontWeight={"600"}>
                                            {formattedEndDate}
                                        </Box>
                                    </FormLabel>
                                )}

                                {displayProducts.map(display => {
                                    const { canDiscountItem, noDiscountReasonText, name, id } = display;
                                    return (
                                        <Flex
                                            flexDirection={["column", "row"]}
                                            key={id}
                                            mb={3}
                                            justifyContent={"flex-start"}
                                        >
                                            <Flex alignItems={"center"}>
                                                <Box
                                                    as={canDiscountItem ? FiTag : IoWarningOutline}
                                                    color={canDiscountItem ? "accent" : ""}
                                                />
                                                <FormLabel fontSize={"md"} m={0} p={0} ml={2}>
                                                    {name}
                                                </FormLabel>
                                            </Flex>
                                            <FormLabel fontSize={"xs"} color={"red.600"} m={0} p={0} ml={[6, 2]} mt={1}>
                                                {!!noDiscountReasonText && `(${noDiscountReasonText})`}
                                            </FormLabel>
                                        </Flex>
                                    );
                                })}
                            </Flex>
                        </TabPanel>
                        <TabPanel key={TAB_NAMES.ELIGIBLE_PRODUCTS}>
                            <OnlineCheckoutDiscountMenus miniMenus={miniMenus} />
                        </TabPanel>
                    </TabPanels>
                </Tabs>
            </ModalBody>
            <ModalActions
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                flexDirection={"column"}
                pt={4}
            >
                {hasDiscountLimit ? (
                    <FormLabel mb={2} ml="auto" mr="auto">
                        <Box as="span">{translate("maxUsage")}: </Box>
                        <Box as="span" color="yellow.900" fontWeight={"600"}>
                            {`${discount.maxRedeemed} ${
                                multiUseDiscount ? translate("manyTimes") : translate("oneTime")
                            }`}
                        </Box>
                    </FormLabel>
                ) : null}
                {showPersonalisedMessage && (
                    <FormLabel fontSize={"sm"} mb={2} pr={0}>
                        <Box as="span" color="gray.600">{`${translate("personalOfferFor")}:`}</Box>
                        <Box as="span">{` ${email}`}</Box>
                    </FormLabel>
                )}
                {!noDiscountedProduct && (
                    <RHCheckBoxInput control={control} name="acceptsCampaigns">
                        {translate("onlineAcceptsCampaigns")}
                    </RHCheckBoxInput>
                )}
                {!hasReachedMinSpend && (
                    <FormLabel fontSize={"sm"} mb={2} pr={0}>
                        <Box as="span" color="red.500">
                            {`* ${translateWithArgument(
                                CAMPAIGN_DISCOUNT_VALIDATION_STATUS.CART_TOTAL_BELOW_MIN_SPEND,
                                formatFinancialNumbers(minimumSpend - cartTotalCost, companyLocale, undefined, true)
                            )}`}
                        </Box>
                    </FormLabel>
                )}

                <Button fullWidth themeColor="blue" borderRadius={"xl"} onClick={onApplyDiscount}>
                    {applyDiscountText}
                </Button>
            </ModalActions>
        </Modal>
    );
};
